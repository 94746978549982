/* current element like this */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff slick-image-url("ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  height: 18px;
  width: 10px;
  outline: 0;
  text-indent: -9999px;
  margin-top: -8px;
  border: 0 none; }

.slick-disabled {
  opacity: .25; }

.slick-prev {
  left: -10px;
  background: url("../images/sprites.svg") no-repeat -125px -111px; }

.slick-next {
  right: -10px;
  background: url("../images/sprites.svg") no-repeat -153px -111px; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -20px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer; }
    .slick-dots li button {
      display: block;
      height: 10px;
      width: 10px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      border-radius: 100%;
      background-color: #fff;
      border: 1px solid #c1c1c1;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      text-indent: -99999px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none;
        background-color: #777777; }
  .slick-dots .slick-active button {
    background-color: #777777; }

.review-container .slick-initialized .slick-slide {
  width: 360px;
  margin-right: 30px; }
  @media (max-width: 320px) {
    .review-container .slick-initialized .slick-slide {
      width: 290px;
      margin-right: 5px; } }
